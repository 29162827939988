<template>
    <select
        class="select-tabela"
        v-bind:value="value"
        @input="emite"
    >
        <option selected value="">{{ placeholder }}</option>
        <option ref="teste" v-for="(opc, i) in opcoes" v-bind:key="i" :value="opc.valor">{{ opc.texto }}</option> 
    </select>
</template>


<script>
    export default {
        name: 'SelectTabela',

        props: {
            placeholder: String,
            value: String,
            opcoes: Array,
        },

        data() {
            return {
                texto: ''
            }
        },

        methods: {
            emite(event) {
                var texto = event.target.value == ''
                    ? ''
                    : this.$refs.teste.filter(item => { return item.value == event.target.value })[0].text
                this.$emit('input', event.target.value, this.placeholder, texto)
            }
        }
    }

</script>

<style scoped>
.select-tabela {
    border-radius: 0px;
    border: none;
    outline: 0;
}
    .select-tabela:focus {
        outline: 0;
    }

</style>