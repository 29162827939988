<template>
    <div>
        <Breadcrumb titulo="ATENDIMENTOS" :items="itensBreadcrumb" backgroundColor="#a1c9ff" color="#000"/>
        <div class="container-fluid py-4">
            <div class="row">
                <div class="col-12">

                    <tabela-atendimentos 
                        class="consulta-atendimentos-page-tabela"
                        :items="atendimentosConsulta == null ? [] : atendimentosConsulta.FiltragemResponse"
                        :quantidadesItems="atendimentosConsulta != null ? (atendimentosConsulta.QuantidadeTotal - 1) : 0"
                        :loading="loadingAtendimentos"
                        :filtrosIniciais="filtrosIniciais"
                        :enumOrigem="enumOrigem"
                        :enumStatus="enumStatus"
                        :enumUsuarios="enumUsuarios"
                        :tabulacoes="listaSelectTabulacao"
                        :atendimentoTabulacoes="atendimentosConsulta == null ? [] : atendimentosConsulta.FiltragemResponse"
                        @buscar-atendimentos="buscarAtendimentosComFiltros"
                    />
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'
import TabelaAtendimentos from '@/components/tabelas/TabelaAtendimentos.vue'
import { mapActions, mapGetters } from 'vuex';
import AtendimentoServices from '@/assets/js/services/AtendimentoServices'

export default {
    name: 'ConsultaAtendimentoPage',

    components: {
        Breadcrumb,
        TabelaAtendimentos,
    },

    data: function() {
        return {
            itensBreadcrumb: [
                {
                    id: '1',
                    classe:'fas fa-search',
                    texto: 'Consultas',
                    link: '/consultas'
                },
                {
                    id:'2',
					classe:'fas fa-table',
                    texto:'Consultas atendimentos',
                    link:''
                },
            ],
            atendimentosConsulta: null,
            itensTabulacao:[],
            loadingAtendimentos: false,
            filtrosIniciais:{dataInicial:'',dataFinal:''}
        }
    },
    
    computed: {
        ...mapGetters({
            enumOrigem: 'atendimento/enumOrigem',
            enumStatus: 'atendimento/enumStatus',
            enumUsuarios: 'usuarios/listaUsuarios',
            listaSelectTabulacao:'atendimento/listaSelectTabulacao',
        }),
        
    },

    created() {
        this.buscaEnumsAtendimentos()  
        this.buscaUsuarios() 
        this.filtrosIniciais = {
            'DataInicio': (new Date()).toLocaleDateString().split('/').reverse().join('-'),
            'DataFim': (new Date()).toLocaleDateString().split('/').reverse().join('-'),
        }
        this.buscarAtendimentosComFiltros(this.filtrosIniciais)
    },

    methods: {
        ...mapActions({
            buscaEnumsAtendimentos: 'atendimento/buscaEnums',
            buscaUsuarios:'usuarios/buscaUsuarios'
        }),
        async buscarAtendimentosComFiltros(filtros) {
            this.loadingAtendimentos = true
            try {
                this.atendimentosConsulta = await AtendimentoServices.filtrarConsultaAtendimento(filtros)
            } catch (e) {
                console.log('erro na consulta de atendimentos', e)
            } finally {
                this.loadingAtendimentos = false
            }
        },
        
    }
}
</script>

<style scoped>
.consulta-atendimentos-page-tabela {
    height: calc(100vh - 180px);
}
</style>