<template>
    <b-row id="rodape_consulta_mensagem" class="align-items-center">
        <b-col :class="isFooter ? 'col-5' : ''">
            <span :style="selecionado > 1 ? 'visibility: visible' : 'visibility: hidden' " class="next_before" @click="filtrar_paginacao('ant')"><</span>

            {{selecionado}} de {{offset}}

            <span :style="selecionado < offset ? 'visibility: visible' : 'visibility: hidden' " class="next_before" @click="filtrar_paginacao('prox')">></span>
        </b-col>
        <b-col class="col-3 text-center" v-if="isFooter && tempoAndQuantidadeTotal !== null">
            <h6 class="m-0">Total de Pausas: {{tempoAndQuantidadeTotal.QuantidadeTotalDePausas}}</h6>
        </b-col>
        <b-col class="text-center" v-if="isFooter && tempoAndQuantidadeTotal !== null">
            <h6 class="m-0">Tempo Total: {{new Date(tempoAndQuantidadeTotal.TempoTotalGasto).toLocaleTimeString()}}</h6>
        </b-col>
    </b-row>
</template>

<script>

    export default {
        name: 'PaginacaoSeta',

        props: {
            selecionado: {
                type: Number,
                default: 1
            },
            offset: {
                type: Number,
                default: 1
            },
            isFooter: {
                type: Boolean,
                default: false
            },
            tempoAndQuantidadeTotal: {
                type: Object,
                default: null
            }
        },

        methods: {
            filtrar_paginacao(acao) {
                let pagina = this.selecionado;
                if (acao == 'ant' && pagina - 1 > 0) pagina--;
                else if (acao == 'prox' && pagina + 1 <= this.offset) pagina++;
                this.$emit('eventoPaginacao', pagina);
            },
        }
    }

</script>

<style scoped>
    .next_before {
        padding: 6px;
        cursor: pointer;
        font-weight: bold;
        font-size: 18px;
        vertical-align: middle;
    }
</style>