<template>
    <div class="tabela-atendimentos">
        <div class="tabela-atendimentos-wrapper">
            <b-container fluid class="w-100 px-0">
                <b-row align-h="between">
                    <b-col>
                        <input type="date" v-model="dataInicial" @change="onFiltrar(1)" class="mr-2 mb-2" />
                        <input type="date" v-model="dataFinal" @change="onFiltrar(1)" class="mr-2 mb-2" />
                        <select v-model="origem" @change="onFiltrar(1)" class="mr-2 mb-2">
                            <option :value="null">Canal</option>
                            <option v-for="canal in enumOrigem" :value="canal.Value">{{ canal.Text }}</option>
                        </select>
                        <select v-model="setor" @change="onFiltrar(1)" class="mr-2 mb-2">
                            <option :value="null">Setor</option>
                            <option v-for="setor in setores" :value="setor.Id">{{ setor.Nome }}</option>
                        </select>
                        <select v-model="status" @change="onFiltrar(1)" class="mr-2 mb-2">
                            <option :value="null">Status</option>
                            <option v-for="status in enumStatus" :value="status.Value">{{ status.Text }}</option>
                        </select>
                        <select v-model="atendente" @change="onFiltrar(1)" class="mr-2 mb-2">
                            <option :value="null">Atendente</option>
                            <option v-for="atendente in enumUsuarios" :value="atendente.Id">{{ atendente.UserName }}
                            </option>
                        </select>
						<input type="text"
                               id="campo-filtro-protocolo"
							   v-model="pesquisadoPorProtocolo"
							   placeholder="Protocolo"
							   @keyup.enter="onFiltrar(1)"
							   class="mr-2 mb-2" />
                        <select v-model="tabulacaoSelecionado" @change="itemTabulacaoSelecionado = null; onFiltrar(1)" class="mr-2 mb-2">
                            <option :value="null">Tabulação</option>
                            <option v-for="tabulacao in tabulacoes" :value="tabulacao">{{ tabulacao.TituloTabulacao }}
                            </option>
                        </select>
                        <select v-if="tabulacaoSelecionado" v-model="itemTabulacaoSelecionado" @change="onFiltrar(1)" class="mr-2 mb-2">
                            <option :value="null">Todos os ítens para {{tabulacaoSelecionado.TituloTabulacao}} </option>
                            <option v-for="item in tabulacaoSelecionado.Itens" :value="item.valor">{{item.texto}}</option>
                        </select>
                    </b-col>
                    <b-col cols="12" md="auto">
                        <b-button class="box-cliente rounded-0 px-4 mr-2 mb-2" @click="exportarTabela"
                            :disabled="exportandoRelatorio">
                            <span v-if="exportandoRelatorio">
                                <b-spinner small class="mr-2"></b-spinner>
                                Exportando...
                            </span>
                            <span v-else>Exportar relatório</span>
                        </b-button>

                        <b-button class="box-cliente rounded-0 px-4 mr-2 mb-2" @click="abreModalEstastiticas"
                            v-if="tabulacaoSelecionado">
                            Estatísticas
                        </b-button>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-table v-model="visibleRows" :items="dados" :fields="fields" :filter="filter"
                            :filter-function="filtrarCustom" :select-mode="selectMode" ref="selectableTable"
                            selected-variant="" responsive striped hover :busy="loading"
                            class="tabela-atendimentos-btable app-scroll-custom w-0" @row-selected="onRowSelected">

                            <template #cell(Usuario)="row">
                                <span>{{ row.value.Nome }}</span>
                            </template>

                            <template #cell(Informacoes)="row">
                                <div v-html="row.value" class="abrir_conversa_padrao"
                                    v-on:click='abrirConversa(row.item.AtendimentoId, row.item.CodigoProtocolo, row.item.ClienteId, row)'>
                                </div>
                            </template>

                            <template #cell(Origem)="row">
                                <span>{{ formataOrigem(row.value) }}</span>
                            </template>

                            <template #cell(Status)="row">
                                <span>{{ formataStatus(row.value) }}</span>
                            </template>

                            <template #table-busy>

                                <div class="text-center py-2">
                                    <b-spinner class="align-middle ml-1"></b-spinner>
                                    <strong class="px-1">Carregando...</strong>
                                </div>

                            </template>
                        </b-table>
                        <div class="tabela-atendimentos-metricas d-flex justify-content-sm-between px-0">
                            <div class="px-2 w-0">
                                <paginacao-seta ref="paginacao_seta" :offset="quantidadesdePaginas"
                                    :selecionado="selecionado" @eventoPaginacao="onFiltrar" />
                            </div>

                            <div class="w-0">
                                <span> {{ quantidadesItems + 1 }} atendimentos(s) </span>
                            </div>

                            <div class="w-0">
                                <span>Tempo Médio: {{ tempoMedio }}</span>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>

        <ModalEstatisticasTabulacao ref="modal_estatistica" :filtradoPorAtendimento="objetoFiltrados"
            :filtradoPorTabulacao="objetoFiltradoPorTabulacao" />

        <ModalConversaPadrao ref="modal_coversa_padrao" :dadosConversa="dadosConversa" />
    </div>
</template>

<script>
import InputPesquisa from '@/components/inputs/InputPesquisa.vue'
import InputDataTabela from '@/components/inputs/InputDataTabela.vue'
import SelectTabela from '@/components/inputs/SelectTabela.vue'
import BotaoPadrao from '@/components/botoes/BotaoPadrao.vue'
import PaginacaoSeta from '@/components/tabelas/paginacao/PaginacaoSeta'
import ExportacaoServices from '@/assets/js/services/ExportacaoServices'
import ModalExportacaoTabelas from '@/components/tabelas/ModalExportacaoTabelas.vue'
import ModalConversaPadrao from '@/components/consultas/ModalCoversaPadrao.vue'
import ModalEstatisticasTabulacao from '@/components/consultas/atendimento/ModalEstatisticasTabulacao.vue'
import axios from "axios";

export default {
    name: 'TabelaAtendimentos',

    components: {
        InputPesquisa,
        BotaoPadrao,
        InputDataTabela,
        SelectTabela,
        PaginacaoSeta,
        ModalExportacaoTabelas,
        ModalEstatisticasTabulacao,
        ModalConversaPadrao,
    },

    props: {
        items: {
            type: Array,
        },
        quantidadesItems: {
            type: Number,
            default: 1
        },
        loading: {
            type: Boolean,
            default: false
        },
        filtrosIniciais: {
            type: Object,
            default: null
        },
        enumOrigem: {
            type: Array,
            default: null
        },
        enumStatus: {
            type: Array,
            default: null
        },
        enumUsuarios: {
            type: Array,
            default: null
        },
        tabulacoes: {
            type: Array,
            default: () => []
        },
        atendimentoTabulacoes: {
            type: Array,
            default: () => []
        }
    },

    data: function () {
        return {
            fields: [
                { key: 'Informacoes', label: 'Informações', sortable: false, sortDirection: 'asc' },
                { key: 'CodigoProtocolo', label: 'Protocolo', sortable: true, sortDirection: 'asc' },
                { key: 'DataInicio', label: 'Data inicial', sortable: true, sortDirection: 'asc' },
                { key: 'Setor', label: 'Setor', sortable: true, sortDirection: 'asc' },
                { key: 'Origem', label: 'Canal', sortable: true, sortDirection: 'asc' },
                { key: 'Inicializado', label: 'Inicializado', sortable: true, sortDirection: 'asc' },
                { key: 'Status', label: 'Status', sortable: true, sortDirection: 'asc' },
                { key: 'Atendente', label: 'Atendente', sortable: true, sortDirection: 'asc' },
                // { key: 'DuracaoSemMilisegundos', label: 'Duração', sortable: true, sortDirection: 'asc' },
                { key: 'Cliente', label: 'Cliente', sortable: true, sortDirection: 'asc' },
                { key: 'Telefone', label: 'Telefone', sortable: true, sortDirection: 'asc' },
                { key: 'Documento', label: 'Documento', sortable: true, sortDirection: 'asc' }
            ],

            filter: null,
            filtrosDisponiveis: ['Informacoes', 'CodigoProtocolo', 'NomeCliente', 'Origem', 'Atendente', 'Status'],
            selectMode: 'single',
            dataInicial: '',
            dataFinal: '',
            origem: '',
            status: '',
            setor: '',
            atendente: '',

            visibleRows: [],
            dados: [],

            labelTabulacao: [],
            totalRepostas: [],
            dadosTabulacao: {},
            dadosConversa: {},
            selecionado: 1,

            listaTabulacaoPaiId: [],
            listaTitulosAuxiliar: [],

            pesquisadoPorProtocolo: null,

            objetoFiltradoPorTabulacaoAuxiliar: [],
            objetoFiltradoPorTabulacao: [],

            tabulacaoSelecionado: null,
            itemTabulacaoSelecionado: null,
            setores: [],
            exportandoRelatorio: false
        }
    },

    watch: {
        items() {
            let verificarCaixa = [];
            this.dados = this.items?.map(atendimento => {
                let dates = new Date(atendimento.DataInicial)
                atendimento.DataInicial = dates.toLocaleDateString('pt-BR')
                let tempo = this.separaMilisegundos(atendimento.DuracaoMilisegundos)
                let novosSelectBox = [];
                var dados = {
                    ...atendimento,
                    // NomeCliente: atendimento.Cliente? atendimento.Cliente.RazaoSocial : "Não Informado",
                    Informacoes: "<i class='fas fa-info-circle fa-2x conversa_funil'></i>",
                    ClienteId: atendimento.Cliente?.Id,
                    ApelidoCliente: atendimento.Cliente?.NomeFantasia,
                    Atendente: atendimento.Atendente,
                    DataInicio: atendimento.DataInicial,
                    DuracaoSemMilisegundos: `${tempo.hours}h ${tempo.minutes}m ${tempo.seconds}s`,
                    Cliente: atendimento.Cliente?.RazaoSocial ?? atendimento.Cliente?.NomeFantasia ?? atendimento.Cliente?.NomeContato,
                    Telefone: atendimento.Cliente?.Telefone,
                    Documento: atendimento.Cliente?.CnpjCpf
                }

                if (atendimento.TituloItem) {

                    this.totalRepostas.push(atendimento.TituloItem)
                }
                //let nomeChave = atendimento.ListaTabulacao.Titulo.replace(/\s"/g, '');



                //if (nomeChave == 'Status') {
                //    console.log(this.enumStatus.find(f => { return f.Enum == atendimento.Status }).Text)
                //    // if (!verificarCaixa.includes(nomeChave)) {
                //    this.fields.push({ key: nomeChave, label: this.enumStatus.find(f => { return f.Enum == atendimento.Status }).Text, sortable: true, sortDirection: 'asc' })
                //    this.filtrosDisponiveis.push(nomeChave)
                //    this.labelTabulacao.push(nomeChave)
                //    novosSelectBox.push({ nomeChave: atendimento.TituloItem });
                //    dados[nomeChave] = atendimento.TituloItem;
                //    verificarCaixa.push(nomeChave)
                //}
                return dados
            })

        },
    },

    computed: {

        objetoFiltrados() {
            return {
                dataInicial: 'Data Inicial: ' + new Date(this.dataInicial).toLocaleDateString('pt-BR').replace(/\//g, '-'),
                dataFinal: ' / Data Final: ' + new Date(this.dataFinal).toLocaleDateString('pt-BR').replace(/\//g, '-'),
                origem: (this.origem != null && this.origem != '') ? (' / Canal: ' + this.formataOrigem(this.origem)) : '',
                status: (this.status != null && this.status != '') ? (' / Status: ' + this.formataStatus(this.status)) : '',
                atendente: (this.atendente != null && this.atendente != '') ? (' / Atendente: ' + this.atendente) : ''
            }
        },

        numeroDeAtendimentosEncontrados() {
            return this.visibleRows?.length
        },

        tempoMedio() {
            if (!this.visibleRows || this.visibleRows.length == 0) {
                return ''
            }

            let mediaEmMilisegundos = this.visibleRows.map(row => {
                return row.DuracaoMilisegundos
            }).reduce((p, c) => p + c, 0) / this.visibleRows.length
            let tempo = this.separaMilisegundos(mediaEmMilisegundos)
            return `${tempo.hours}h ${tempo.minutes}m ${tempo.seconds}s`
        },

        quantidadesdePaginas() {
            return Math.floor(this.quantidadesItems / 10) + 1
        }

    },

    mounted() {
        axios.get(`api/setor/lista-basica`).then(response => {
            this.setores = response.data;
        })

        if (this.filtrosIniciais) {
            this.dataInicial = this.filtrosIniciais.DataInicio
            this.dataFinal = this.filtrosIniciais.DataFim
            this.origem = null
            this.status = null
            this.setor = null
            this.atendente = null
        }
    },

    methods: {

        separaMilisegundos(milisegundos) {
            var seconds = Math.floor((milisegundos / 1000) % 60),
                minutes = Math.floor((milisegundos / (1000 * 60)) % 60),
                hours = Math.floor((milisegundos / (1000 * 60 * 60)) % 24);

            return {
                seconds: seconds,
                minutes: minutes,
                hours: hours,
            }
        },

        onRowSelected(items) {
            if (!items || items.length == 0 || !items[0]) {
                return
            }
            this.$emit('atendimento-selecionado', items[0])
        },

            onFiltrar(selecionado) {
                this.totalRepostas = []
                this.$emit('buscar-atendimentos', {
                    'DataInicio': this.dataInicial,
                    'DataFim': this.dataFinal,
                    'CodigoProtocolo': this.pesquisadoPorProtocolo,
                    'Origem': this.origem,
                    'SetorId': this.setor,
                    'Status': this.status,
                    'AtendenteId': this.atendente === '' ? null : this.atendente,
                    'TabulacaoId': this.tabulacaoSelecionado?.Id,
                    'TabulacaoItemId': this.itemTabulacaoSelecionado,
                    'Limit': 10,
                    'Offset': (selecionado - 1) * 10,
                })
                this.selecionado = selecionado;
            },

        onFiltrarTexto(texto) {
            if (!texto && !this.filter) {
                return
            }
            if (!texto && this.filter) {
                this.filter = { ...this.filter, texto: null }
                return
            }
            if (!this.filter) {
                this.filter = {}
            }
            this.filter.texto = texto
        },

        filtrarCustom(item, filtros) {
            if (!filtros) {
                return true
            }
            if (filtros.origem && filtros.origem != item.Origem) {
                return false
            }
            if (filtros.status && filtros.status != item.Status) {
                return false
            }
            if (filtros.setor && filtros.setor != item.Setor) {
                return false
            }
            if (filtros.texto) {
                let camposConcatenadosComoString = this.filtrosDisponiveis.map(nomeFiltro => item[nomeFiltro]?.toString().toLowerCase()).filter(item => !!item).join(',')
                let naoEncontrouTexto = camposConcatenadosComoString.search(filtros.texto.toLowerCase()) < 0
                if (naoEncontrouTexto) {
                    return false
                }
            }
            return true
        },

        formataOrigem(valorOrigem) {
            return this.enumOrigem?.find(item => item.Value == valorOrigem)?.Text
        },

        formataStatus(valorStatus) {
            return this.enumStatus?.find(item => item.Value == valorStatus)?.Text
        },
        formataSetor(valorSetor) {
            return this.enumSetor?.find(item => item.Value == valorSetor)?.Text
        },

        exportarTabela() {
            let linhasComColunasVisiveis = {
                DataInicio: this.dataInicial,
                DataFim: this.dataFinal,
                Origem: this.origem,
                Status: this.status,
                SetorId: this.setor,
                AtendenteId: this.atendente,
                TabulacaoId: this.tabulacaoSelecionado?.Id,
                TabulacaoItemId: this.itemTabulacaoSelecionado,
                CodigoProtocolo: this.pesquisadoPorProtocolo
            }
            this.exportandoRelatorio = true;
            ExportacaoServices.exportarPlanilhaSimples(linhasComColunasVisiveis, 'tabela_atendimentos.xlsx').then(() => {
                this.exportandoRelatorio = false;
            }).catch(() => {
                this.exportandoRelatorio = false;
            });
        },

        filtroTabulacao(tabulacaoPaiId, titulo, texto) {
            var indexTabulacaoFiltrado = this.objetoFiltradoPorTabulacaoAuxiliar.findIndex(item => item == titulo);
            if (indexTabulacaoFiltrado != -1) {
                if (texto == '') {
                    this.objetoFiltradoPorTabulacao.splice(indexTabulacaoFiltrado, 1);
                    this.objetoFiltradoPorTabulacaoAuxiliar.splice(indexTabulacaoFiltrado, 1);
                }
                else {
                    this.objetoFiltradoPorTabulacao[indexTabulacaoFiltrado] = `${titulo}: ${texto}`;
                }
            }
            else {
                this.objetoFiltradoPorTabulacaoAuxiliar.push(titulo);
                this.objetoFiltradoPorTabulacao.push(`${titulo}: ${texto}`);

            }

            var indexTitulo = this.listaTitulosAuxiliar.findIndex(item => item == titulo)
            if (tabulacaoPaiId == '' && indexTitulo != -1) {
                this.listaTabulacaoPaiId.splice(indexTitulo, 1)
                this.listaTitulosAuxiliar.splice(indexTitulo, 1)
            }

            else if (indexTitulo == -1) {
                this.listaTabulacaoPaiId.push(tabulacaoPaiId)
                this.listaTitulosAuxiliar.push(titulo)
            }

            else if (indexTitulo != -1) {
                this.listaTabulacaoPaiId[indexTitulo] = tabulacaoPaiId
            }
        },

        formataGraficos() {
            if (this.tabulacoes && this.totalRepostas.length == 0 && this.tabulacoes.length == 0) {
                return []
            }

            this.dadosTabulacao = this.tabulacoes.filter(f => f.Id == this.tabulacaoSelecionado?.Id).map(select => {
                let dado = {};
                let label = [];
                let numero = [];

                for (var i in select.Itens) {
                    label.push(select.Itens[i].texto)
                    numero.push(this.totalRepostas.filter(texto => texto.toLowerCase() === select.Itens[i].texto.toLowerCase()).length)
                }
                dado.nome = select.TituloTabulacao
                dado.texto = label
                dado.valor = numero

                return { dados: dado }
            });

            return this.dadosTabulacao

        },

        abreModalEstastiticas() {
            this.formataGraficos();
            this.$refs.modal_estatistica.setDados(this.dadosTabulacao, this.atendimentoTabulacoes)
            this.$refs.modal_estatistica.show()
        },

        abrirConversa: function (id, protocolo, clienteId) {
            //console.log("Id: " + id + ", Protocolo: " + protocolo + ", clienteId: " + clienteId)
            const valor = { Id: id, Protocolo: protocolo, ClienteId: clienteId }
            this.dadosConversa = valor;
            this.$refs.modal_coversa_padrao.show()
        }
    },

}
</script>

<style scoped>
    select, input {
        min-width: 100px;
        height: 34px;
        box-sizing: border-box;
    }

#campo-filtro-protocolo {
    width: 100px;
}

.tabela-atendimentos {
    padding: 12px;
    background-color: var(--cinza-3);
    width: 100%;
    font-size: 12px;
}

.tabela-atendimentos-wrapper {
    height: 100%;
}

.tabela-atendimentos-btable {
    background-color: #fff;
    height: calc(100vh - 335px);
    margin-bottom: 0 !important;
    font-size: 12px;
}

.tabela-atendimentos-metricas {
    color: var(--cinza-5);
    font-weight: bold;
    height: 40px;
    padding: 10px 5px 0px 5px;
    font-size: 10px;
    align-items: baseline;
}

.abrir_conversa_padrao {
    display: table;
    cursor: pointer;
}
</style>