<template>
    <b-form-datepicker
        class="input-data-tabela"
        size="sm"
        v-bind:value="value"
        @input="emite"
        v-bind="labels[locale] || {}"
        :locale="locale"
        :placeholder="placeholder"
        :show-decade-nav="false"
        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
    ></b-form-datepicker>
</template>

<script>
    export default {
        name: 'InputDataTabela',

        props: {
            placeholder: String,
            value: String,
        },

        data() {
            return {
                locale: 'pt-BR',
                labels: {
                    'pt-BR': {
                        labelPrevDecade: 'Década anterior',
                        labelPrevYear: 'Ano anterior',
                        labelPrevMonth: 'Mês anterior',
                        labelCurrentMonth: 'Mês atual',
                        labelNextMonth: 'Próximo mês',
                        labelNextYear: 'Próximo ano',
                        labelNextDecade: 'Próxima década',
                        labelToday: 'Hoje',
                        labelSelected: 'Selecionado',
                        labelNoDateSelected: 'Sem data',
                        labelCalendar: 'Calendário',
                        labelNav: 'LabelNav',
                        labelHelp: ''
                    },
                }
            }
        },

        methods: {
            emite(valor) {
                this.$emit('input', valor)
            }
        }
    }

</script>

<style scoped>
.input-data-tabela {
    border-radius: 0px;
}

</style>